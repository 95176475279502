require("../vendor/bxslider/v4.1.2/jquery.bxslider.min");

function bxSlider(){

        if ($(".bx-slider li").length > 1) {
            $(".bx-slider").bxSlider({
                auto: true,
                autoControls: false,
                mode:'horizontal',
                pagerCustom: '.bx-pager',
                captions: true,
                controls: true,
                responsive: true,
                pause: 6000,
                preventDefaultSwipeY: false,
                touchEnabled: false,
                onSlideBefore: function(){
                    $('.bx-clone').css('display', 'block');
                }
            });
        }
        else {
            $(".bx-slider").bxSlider({
                auto: false,
                autoControls: false,
                mode:'horizontal',
                captions: true,
                controls: false,
                responsive: true,
                pause: 6000,
                pager: false,
                preventDefaultSwipeY: false,
                touchEnabled: false,
                onSlideBefore: function(){
                    $('.bx-clone').css('display', 'block');
                }
            });
            $('.bx-pager').css('display','none');
        }
    }

module.exports = bxSlider;