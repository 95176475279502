//Require
window.$ = window.jQuery = require("./vendor/jquery/v1.11.3/jquery-1.11.3.min");
var modernizr = require("./src/modernizr");
var bootstrapValidator = require("./src/bootstrapvalidator");
var mobileMenu = require("./src/mobilemenu");
var bxSlider = require("./src/bxslider");
//var loadHTMLparts = require("./src/loadhtmlparts");

//Execute scripts
modernizr.checkSupportsvg();
modernizr.checkDevices();
mobileMenu();
bxSlider();
bootstrapValidator();
//loadHTMLparts();
